var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-row',[_c('el-col',{attrs:{"span":8}},[_c('el-form',{ref:"examStudentForm",attrs:{"label-position":"right","label-width":"140px","model":_vm.formData,"rules":_vm.rules}},[_c('el-form-item',{attrs:{"label":"考试项目","prop":"examProjectId"}},[_c('el-select',{attrs:{"placeholder":"请选择考试项目"},model:{value:(_vm.formData.examProjectId),callback:function ($$v) {_vm.$set(_vm.formData, "examProjectId", $$v)},expression:"formData.examProjectId"}},_vm._l((_vm.examProjectList),function(item){return _c('el-option',{key:item.examProjectId,attrs:{"label":item.examProjectName,"value":item.examProjectId}})}),1)],1),_c('el-form-item',{attrs:{"label":"考点","prop":"cascadeExamSchoolId"}},[_c('el-cascader',{staticStyle:{"width":"100%"},attrs:{"options":_vm.schools,"props":{
              value: 'keyId',
              label: 'keyValue',
              leaf: 'examSchoolId',
              expandTrigger: 'hover',
            },"filterable":"","show-all-levels":false},model:{value:(_vm.formData.cascadeExamSchoolId),callback:function ($$v) {_vm.$set(_vm.formData, "cascadeExamSchoolId", $$v)},expression:"formData.cascadeExamSchoolId"}})],1),_c('el-form-item',{attrs:{"label":"姓名","prop":"examStudentName"}},[_c('el-input',{model:{value:(_vm.formData.examStudentName),callback:function ($$v) {_vm.$set(_vm.formData, "examStudentName", $$v)},expression:"formData.examStudentName"}})],1),_c('el-form-item',{attrs:{"label":"性别","prop":"sex"}},[_c('el-radio-group',{model:{value:(_vm.formData.sex),callback:function ($$v) {_vm.$set(_vm.formData, "sex", $$v)},expression:"formData.sex"}},[_c('el-radio',{attrs:{"label":"男"}},[_vm._v("男")]),_c('el-radio',{attrs:{"label":"女"}},[_vm._v("女")])],1)],1),_c('el-form-item',{attrs:{"label":"考号","prop":"examAccount"}},[_c('el-input',{model:{value:(_vm.formData.examAccount),callback:function ($$v) {_vm.$set(_vm.formData, "examAccount", $$v)},expression:"formData.examAccount"}})],1),_c('el-form-item',{attrs:{"label":"考生学校","prop":"cascadeSchoolId"}},[_c('el-cascader',{staticStyle:{"width":"100%"},attrs:{"options":_vm.schools,"props":{
              value: 'keyId',
              label: 'keyValue',
              leaf: 'schoolId',
              expandTrigger: 'hover',
            },"filterable":"","show-all-levels":false},model:{value:(_vm.formData.cascadeSchoolId),callback:function ($$v) {_vm.$set(_vm.formData, "cascadeSchoolId", $$v)},expression:"formData.cascadeSchoolId"}})],1),_c('el-form-item',{attrs:{"label":"考生班级","prop":"className"}},[_c('el-input',{model:{value:(_vm.formData.className),callback:function ($$v) {_vm.$set(_vm.formData, "className", $$v)},expression:"formData.className"}})],1),_c('el-form-item',{staticStyle:{"margin-top":"30px"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.doConfirmClick('examStudentForm')}}},[_vm._v("确 定")]),_c('el-button',{on:{"click":_vm.doGoBackClick}},[_vm._v("返 回")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }