


















































































import { Component, Vue } from "vue-property-decorator";
import { ExamStudent } from "@/tool/_class";
import * as _ from "lodash";
import { getSchoolCascadeList } from "@/api/school";
import { getExamProjectList } from "@/api/examProject";
import { postExamStudent, getExamStudent } from "@/api/examStudent";
import { ExamProject } from "@/tool/interface-index";
import path from "path";

class FormData extends ExamStudent {
  cascadeSchoolId: string[] = [];
  cascadeExamSchoolId: string[] = [];
}

@Component({
  name: "ExamStudentEditor",
  components: {},
})
export default class extends Vue {
  private formData: FormData = new FormData();

  private schools: any[] = [];
  private examProjectList: ExamProject[] = [];

  private rules: any = {
    examProjectId: [
      {
        required: true,
        message: "请选择考试项目",
        trigger: "blur",
      },
    ],
    examStudentName: [
      {
        required: true,
        message: "请输入姓名",
        trigger: "blur",
      },
    ],
    sex: [
      {
        required: true,
        message: "性别不能为空",
        trigger: "blur",
      },
    ],
    examAccount: [
      {
        required: true,
        message: "请输入考号",
        trigger: "blur",
      },
    ],
    cascadeExamSchoolId: [
      {
        required: true,
        message: "请选择考点",
        trigger: "blur",
      },
    ],
    cascadeSchoolId: [
      {
        required: true,
        message: "请选择考生学校",
        trigger: "blur",
      },
    ],
    className: [
      {
        required: true,
        message: "请输入考生班级",
        trigger: "blur",
      },
    ],
  };

  private getCascadeItem(keyId: string, cascades: any[]): any {
    let result = undefined;
    cascades.some(function iter(obj) {
      if (obj.keyId == keyId) {
        result = obj;
        return true;
      }
      return Array.isArray(obj.children) && obj.children.some(iter);
    });
    return result;
    /*
    for (let i = 0; i < cascades.length; i++) {
      if (cascades[i].keyId == keyId) {
        return cascades[i]
      }
      if (_.has(cascades[i], 'children')) {
        return this.getCascadeItem(keyId, cascades[i].children);
      }

    }
    */
  }

  private getCascadeSchoolId(schoolId: string): string[] {
    let arr: string[] = [];
    let keyId: string = schoolId;
    do {
      const item = this.getCascadeItem(keyId, this.schools);
      if (item) {
        arr.push(keyId);
        keyId = item.parentKeyId;

        continue;
      }
      break;
    } while (true);
    return arr.reverse();
  }

  private removeNullChildren(cascades: any[]) {
    let i: number = 0;
    while (i < cascades.length) {
      switch (_.get(cascades[i], "dataType", "")) {
        case "school": {
          if (_.get(cascades[i], "children", []).length == 0) {
            delete cascades[i]["children"];
          } else {
            this.removeNullChildren(cascades[i].children);
          }
          i++;
          break;
        }
        case "area": {
          if (_.get(cascades[i], "children", []).length == 0) {
            cascades.splice(i, 1);
          } else {
            this.removeNullChildren(cascades[i].children);
            i++;
          }
          break;
        }
        default:
          i++;
      } //switch
    }
  }

  mounted() {
    this.formData.cascadeExamSchoolId =
      (this.$route.query.cascadeExamSchoolId as string[]) || [];
    this.formData.examProjectId =
      (this.$route.query.examProjectId as string) || "";

    getSchoolCascadeList().then(({ data }) => {
      this.schools = data.items;
      this.removeNullChildren(this.schools);
    });

    getExamProjectList({
      examType: "formalExamMode",
      pageSize: 999,
      curPage: 1,
    }).then((res) => {
      this.examProjectList = res.data.items;
    });

    let examStudentId: string = "";
    const pathArr: string[] = this.$route.path.split("/");
    switch (_.last(pathArr)) {
      case "newExamStudent": {
        //examStudentId = this.formData.examStudentId;
        break;
      }
      case "editExamStudent": {
        examStudentId = this.$route.query.examStudentId as string;
        getExamStudent(examStudentId).then(({ data }) => {
          _.merge(this.formData, data);
          this.formData.cascadeSchoolId = this.getCascadeSchoolId(
            this.formData.schoolId
          );
          this.formData.cascadeExamSchoolId = this.getCascadeSchoolId(
            this.formData.examSchoolId
          );
          console.log(`editExamStudent: ${JSON.stringify(this.formData)}`);
        });
        break;
      }
      default:
        this.$router.go(-1);
    }
  }

  private doGoBackClick() {
    this.$router.go(-1);
  }
  private doConfirmClick(formName: string) {
    //console.log(`${JSON.stringify(this.formData)}`);

    let __valid: boolean = false;
    (this.$refs[formName] as any).validate((valid: boolean) => {
      __valid = valid;
      return valid;
    });

    if (!__valid) {
      return;
    }

    const obj1: any = this.getCascadeItem(
      _.last(this.formData.cascadeSchoolId)!,
      this.schools
    );
    this.formData.schoolId = obj1.keyId;
    this.formData.schoolName = obj1.keyValue;

    const obj2: any = this.getCascadeItem(
      _.last(this.formData.cascadeExamSchoolId)!,
      this.schools
    );
    this.formData.examSchoolId = obj2.keyId;
    //this.formData.examSchoolName = obj2.keyValue;

    postExamStudent(this.formData)
      .then(({ data }) => {
        this.$message({
          message: "添加考生成功。",
          type: "success",
          duration: 5 * 1000, // 100000
          offset: 60,
        });
      })
      .catch((error) => {
        let resp: any = error!.response;
        let msg: string = "";
        if (resp) {
          msg = resp!.msg;
        } else {
          // let errorUrl = error.config.url;
          msg = error.message;
          // errorUrl.substring(errorUrl.lastIndexOf("/") + 1) +":" +error.message;
        }
        this.$message({
          message: msg,
          type: "error",
          duration: 5 * 1000, // 100000
          offset: 60,
        });
      });
  }
}
